import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import styled from 'styled-components';

const MenuWrapper = styled.div`

    text-align:right;
    padding:8px;

    @media (max-width:500px) {
        position:relative;
        top:0;
        left:0;
        width:100%;
        text-align:center;
    }

`;

const LinkStyled = styled(Link)`

    position:relative;
    padding:10px;
    color:white;
    text-transform:uppercase;
    text-decoration: none;
    font-size:11px;
    font-weight:bold;

    &.active::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        border-bottom: 4px solid yellow;

        @media (max-width:500px) {
            border-bottom: 0;
        }

    }

    @media (max-width:500px) {
        display:block;
        border-bottom: 0;
    }

`;

const Menu = () => {

    return (

    <MenuWrapper>
        <LinkStyled className="active">jsa-volley-bordeaux.fr</LinkStyled>
        <LinkStyled to="/adm/teams/list">espace entraîneur</LinkStyled>
        <LinkStyled to="http://www.ffvb.org/" target="_blank">FFVB</LinkStyled>
        <LinkStyled to="https://lnv.fr" target="_blank">LNV</LinkStyled>
    </MenuWrapper>

    );

}

export default Menu;