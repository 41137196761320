import styled from "styled-components";
import LoginBox from "../../components/Security/LoginBox/LoginBox";

const LoginWrapper = styled.div `
    position:fixed;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background:#ecf0f1;
    display:flex;
    justify-content:center;
    align-items:center;
`

const Login = () => {


    return (

    <LoginWrapper>
        <LoginBox />
    </LoginWrapper>

    );
}

export default Login;