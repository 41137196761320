import React, { useEffect } from "react";
import styled from "styled-components";

const ShopWrapper = styled.div `
    position:relative;
    top:0;
    left:0;
    width:100%;
    display:flex;
    flex-direction:column;
    align-items:center;
`

const Shop = () => {

    useEffect(() => {
        const titlepage = document.getElementById("titlepage");
        titlepage.innerText = "Boutique";
        const titlehtml = document.querySelector("title");
        titlehtml.innerText = "JSA Volley Ball - Boutique";
    });

    return (

    <ShopWrapper>
        <br /><br /> <br />
        Bientôt en ligne ! 
        <br /><br /><br /><br /><br /> <br /><br /><br /> <br /><br /><br /> <br />
    </ShopWrapper>

    );
}

export default Shop;