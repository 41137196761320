import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import styled from 'styled-components';

const DivStyled = styled.div`

    text-align:right;
    padding:8px;

    @media (max-width:500px) {
        text-align:center;
    }

`;

const StyledLink = styled(Link) `

    position:relative;
    padding:16px;
    color:black;
    text-transform: uppercase;
    text-decoration: none;
    font-size:15px;
    line-height:80px;
    font-weight:500;
    transition:all .3s ease;

    &.active {
        color:black;
        background:#ecf0f1;
        border-radius:10px;
    }

    &:hover {
        transition: all .3s ease;
        color:#ffed00;
    }

    @media (max-width:500px) {
        display:block;
        line-height:8px;
    }

`

const Menu = () => {

    const location = useLocation().pathname;

    return (

    <DivStyled>
        <StyledLink to="/" className={location === '/' ? 'active' : ''}>Accueil</StyledLink>
        <StyledLink to="/teams" className={location === '/teams' || location.substring(0,5) === '/team' ? 'active' : ''}>Equipes</StyledLink>
        <StyledLink to="/history" className={location === '/history' ? 'active' : ''}>Histoire et Palmarès</StyledLink>
        <StyledLink to="/shop" className={location === '/shop' ? 'active' : ''}>Boutique</StyledLink>
    </DivStyled>

    );

}

export default Menu;